import { useState } from "react";
import styles from "../styles/stateGates.module.css";
import ironStyles from "../styles/IronMaterial.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const StateGates = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);
  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <div className={ironStyles.conImage}>
          <img src={require("../public/woodRecurse6.png")} />
          <div
            style={{
              background: "rgb(255,155,73)",
              background:
                "linear-gradient(90deg, rgba(255,155,73,.4) 0%, rgba(255,114,43,.4) 100%)",
            }}
          ></div>
        </div>
        <div className={styles.contProducst}>
          <div>
            <div>
              <img src={require("../public/gate1.png")} />
              <span>
                Iron Estate Gates 60 Inch x 12- Foot Single - Yukon (12´)
              </span>
            </div>
            <div>
              <img src={require("../public/gate2.png")} />
              <span>
                Iron Estate Gates 60 Inch x 16- Foot Double - Regal (16´)
              </span>
            </div>
            <div>
              <img src={require("../public/gate3.png")} />
              <span>
                Iron Estate Gates 60 Inch x 12- Foot Single -Regal (12´)
              </span>
            </div>
          </div>
          <div>
            <div>
              <img src={require("../public/gate4.png")} />
              <span>
                Iron Estate Gates 60 Inch x 10- Foot Single - Denali (10´)
              </span>
            </div>
            <div>
              <img src={require("../public/gate5.png")} />
              <span>
                Iron Estate Gates 60 Inch x 12- Foot Double - Denali (12´)
              </span>
            </div>
            <div>
              <img src={require("../public/gate6.png")} />
              <span>
                Iron Estate Gates 60 Inch x 12- Foot Single - Denali (12´)
              </span>
            </div>
          </div>
          <div id={styles.contlastElement} >
            <div id={styles.lastElement} >
              <img src={require("../public/gate7.png")} />
              <span>
                Iron Estate Gates 60 Inch x 16- Foot Double - Denali (16´)
              </span>
            </div>
          </div>
          <article className={styles.contBotton}>
            <button
              onClick={() => {
                setActiveForm(true);
              }}
            >
              CONTACT US{" "}
            </button>
          </article>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </>
  );
};

export default StateGates;
