import "./App.css";
import Header from "./componets/Header";
import { Home } from "./componets/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import  Services  from "./componets/Services";
import WhoAreWe from "./componets/WhoAreWe";
import Portfolio from "./componets/Portfolio";
import Material from "./componets/Material";
import WoodMaterials from "./componets/WoodMaterials";
import IronMaterials from "./componets/IronMaterials";
import HandrailReference from "./componets/HandrailReference";
import StateGates from "./componets/StateGates";
import FensePanel from "./componets/FensePanel";
import Pergola from "./componets/Pergola";
import FrequentQuestions from "./componets/FrequentQuestions";
import ResidencialGalery from "./componets/ResidencialGalery";
import ComercialGalery from "./componets/ComercialGalery";
import Testimonials from "./componets/Testimonials";
import Warranty from "./componets/Warranty";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/WhoAreWe" element={<WhoAreWe />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Material" element={<Material />} />
        <Route path="/WoodMaterial" element={<WoodMaterials />} />
        <Route path="/IronMaterial" element={<IronMaterials />} />
        <Route path="/HandrailReference" element={<HandrailReference />} />
        <Route path="/StateGates" element={<StateGates />} />
        <Route path="/FensePanel" element={<FensePanel />} />
        <Route path="/Pergola" element={<Pergola />} />
        <Route path="/FrequentQuestions" element={<FrequentQuestions />} />
        <Route path="/residencialGalery" element={<ResidencialGalery />} />
        <Route path="/ComercialGalery" element={<ComercialGalery />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/Warranty" element={<Warranty />} />
      </Routes>
    </BrowserRouter>
  );
}

/*

<div className="App">
      <Home/>
    </div>
 */
export default App;
