import { useState } from "react";
import styles from "../styles/frequentQuestion.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const Warranty = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div>
        <div className={styles.contTitle}>
          <img src={require("../public/waranty.png")} />
          <div className={styles.decoTitle}></div>
          <div className={styles.title1}>
            <h1>WARRANTY</h1>
            <span></span>
          </div>
        </div>
        <div className={styles.container}>
          <ol style={{ listStyleType: "none" }}>
            <li className={styles.list1}>
              <p>
                The warranty only covers the installation of the materials in a
                correct and professional way. Javier's Fence is not responsible
                for defective manufacturing materials since at the time of the
                installation, it will be checked that no broken or defective
                materials are installed.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                Metal posted alignments, they are installed in a professional
                way so that at the time of the installation of the wood; it wont
                be misaligned.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                Installation of the cap, all wood by nature does not come 100%
                clean by the knots that Javier's Fence has installed the wood in
                the correct way and clean.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                Doors, All doors that are made out of wood tend to open from the
                bottom when cutting the rails. Their is treated material for
                this particular case. Javier's Fence recommends installing metal
                frames for the doors, as they are better for greater durability
                and are very resistant materials! Call for more information
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                Stain, Javier's Fence installs the stain for all its customers
                in a professional and clean way. The guarantee offer is the
                stain offered by the supplier 2 years maximum.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                All poles are installed at 36" depth for greater support and
                stability.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                All jobs use maximum concrete 5,000 lbs price for greater
                strength.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                All cedar wood is installed with galvanized nails that has a
                space of 3 1/2" of space between one of the boards to be able to
                install the nail correctly in the space between the two boards.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                Javier's Fence is not responsible for damaged sprinklers in the
                installation of the fence. Since it is impossible to see exactly
                where the water lines go, we will turn on the sprinklers to see
                which direction they are running to.
              </p>
            </li>
            <li className={styles.list1}>
              <p>
                Javier's Fence does not offer exchange since the product is
                installed already and he does not offer refunds. At the time of
                signing the estimate and the contract, you accept the terms and
                conditions
              </p>
            </li>
          </ol>
          <div className={styles.contBton}>
            <button
              onClick={() => {
                setActiveForm(true);
              }}
            >
              CONTACT US 
            </button>
          </div>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </>
  );
};

export default Warranty;
