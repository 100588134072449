import {useState} from "react";
import stylesWood from "../styles/woodMaterial.module.css";
import styles from "../styles/IronMaterial.module.css";
import Header from "./Header";
import Footer from "../View/Footer";
import ContactForm from "../View/ContactForm";

const IronMaterials = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);
  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <div className={styles.conImage}>
          <img src={require("../public/woodRecurse6.png")} />
          <div
            style={{
              background: "rgb(255,155,73)",
              background:
                "linear-gradient(90deg, rgba(255,155,73,.4) 0%, rgba(255,114,43,.4) 100%)",
            }}
          ></div>
        </div>
        <div>
          <div className={styles.contTitle}>
            <h1>IRON</h1>
          </div>
          <div className={styles.contProducs}>
            <div>
              <div>
                <img src={require("../public/ironResurce1.png")} />
                <span>Estate Gates</span>
                <a href="/StateGates">
                  <button>Reference</button>
                </a>
              </div>
              <div>
                <img src={require("../public/ironResurse5.png")} />
                <span>Handrails</span>
                <a href="/HandrailReference">
                  <button>Reference</button>
                </a>
              </div>
            </div>
            <div>
              <div>
                <img src={require("../public/ironResurse2.png")} />
                <span>Animal Containment</span>
              </div>
              <div>
                <img src={require("../public/ironResurse4.png")} />
                <span>Fence Panels</span>
                <a href="/FensePanel">
                  <button>Reference</button>
                </a>
              </div>
            </div>
            <div>
              <div>
                <img src={require("../public/IronResurse3.png")} />
                <span>Fence Gates</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </>
  );
};

export default IronMaterials;
