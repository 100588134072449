import { useState, useEffect } from "react";
import styles from "../styles/residencialGalery.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const cantImages = 150;
const imagesPerLoad = 20;

const ResidencialGalery = () => {
  const [images, setImages] = useState([]);
  const [imagesSelected, setImagesSelected] = useState([]);
  const [offset, setOffset] = useState(20);
  const [activeForm, setActiveForm] = useState(false);
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSource] = useState("");

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  useEffect(() => {
    const imagesCopied = [];

    for (let i = 0; i <= cantImages; i++) {
      try {
        const img = require(`../public/proyectosResidenciales/residenciales-${
          1000 + i
        }.jpg`);
        imagesCopied.push({ id: i, source: img });
      } catch (error) {
        const img = require(`../public/proyectosResidenciales/residenciales-${
          1000 + i
        }.PNG`);
        imagesCopied.push({ id: i, source: img });
      }
    }
    const imagesSelectedCopy = imagesCopied.slice(0, imagesPerLoad);
    setImages(imagesCopied);

    setImagesSelected([{ galery: [...imagesSelectedCopy] }]);
  }, []);

  useEffect(() => {
    const loadImages = () => {
      const start = offset;
      const end = start + imagesPerLoad;
      const newImages = images.slice(start, end);
      setImagesSelected([...imagesSelected, { galery: [...newImages] }]);
      setOffset(end);
    };

    const checkScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadImages();
      }
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [images, offset]);

  const getImg = (imgSrc) => {
    setTempImgSource(imgSrc);
    setModel(true);
  };

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={model ? `${styles.model} ${styles.open}` : styles.model}>
        <svg
          onClick={()=>{setModel(false)}}
          class="icon icon-tabler icon-tabler-square-rounded-x-filled"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
            fill="#fff"
            stroke-width="0"
          ></path>
        </svg>
        <img src={tempImgSrc} />
      </div>
      <div className={styles.container}>
        <h1>Residential projects</h1>
        {imagesSelected.map((el, index) => (
          <div className={styles.galery} key={index}>
            {el.galery.map((item) => (
              <div
                className={styles.pics}
                key={item.id}
                onClick={() => {
                  getImg(item.source);
                }}
              >
                <img src={item.source} style={{ width: "100%" }} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ResidencialGalery;
