import { useState } from "react";
import styles from "../styles/Materials.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const Material = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <div className={styles.container}>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.deco1}></div>
      <div className={styles.deco2}></div>
      <div className={styles.conTitle}>
        <h1>MATERIALS</h1>
      </div>
      <div className={styles.contMaterial}>
        <div className={styles.materialTop}>
          <div className={styles.contImage}>
            <img src={require("../public/Recurso1.png")} />
          </div>

          <a href="/WoodMaterial" style={{ color: "#000" }}>
            <div className={styles.conText}>WOOD</div>
          </a>
        </div>
        <div className={styles.materialBottom}>
          <div className={styles.contImage}>
            <img src={require("../public/Recurso2.png")} />
          </div>

          <a href="/Pergola">
            <div className={styles.conText} style={{ color: "#000" }}>
              PERGOLA - PERGOLA DELUX
            </div>
          </a>
        </div>
        <div className={styles.materialTop}>
          <div className={styles.contImage}>
            <img src={require("../public/Recurso3.png")} />
          </div>

          <a href="/IronMaterial">
            <div className={styles.conText} style={{ color: "#000" }}>
              IRON
            </div>
          </a>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </div>
  );
};

export default Material;
