import { useState } from "react";
import Header from "./Header";
import styles from "../styles/frequentQuestion.module.css";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";
const Testimonials = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);
  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div>
        <div className={styles.contTitle}>
          <img src={require("../public/metalfense7.jpg")} />
          <div className={styles.decoTitle1}></div>
          <div className={styles.title}>
            <h1>TESTIMONIALS</h1>
            <span></span>
          </div>
        </div>
        <div className={styles.contCard}>
          <div className={styles.card}>
            <img
              className={styles.deco1}
              src={require("../public/comillas.png")}
            />
            <img
              className={styles.deco2}
              src={require("../public/comillas.png")}
            />
            <img className={styles.deco3} src={require("../public/user.png")} />

            <div className={styles.cardTitle}>
              <h3>TESTIMONIAL</h3>
              <p>
                I am delighted with the quality and durability of the treated
                wood fence you built - you really lived up to my expectations
                and the end result is impressive!
              </p>
            </div>
            <div className={styles.contStart}>
              <div style={{ marginTop: 10 }}>
                <img src={require("../public/estrella.png")} />
              </div>
              <div>
                <span>Benjamin</span>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <img
              className={styles.deco1}
              src={require("../public/comillas.png")}
            />
            <img
              className={styles.deco2}
              src={require("../public/comillas.png")}
            />
            <img className={styles.deco3} src={require("../public/user.png")} />

            <div className={styles.cardTitle}>
              <h3>TESTIMONIAL</h3>
              <p>
                The construction team demonstrated great proficiency in
                fabrication and erection of metal structures. Our new building
                is sturdy, safe and visually stunning ¡I would recommend their
                services to anyone!
              </p>
            </div>
            <div className={styles.contStart}>
              <div style={{ marginTop: 10 }}>
                <img src={require("../public/estrella.png")} />
              </div>
              <div>
                <span>Noah</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.contCard}>
          <div className={styles.card}>
            <img
              className={styles.deco1}
              src={require("../public/comillas.png")}
            />
            <img
              className={styles.deco2}
              src={require("../public/comillas.png")}
            />
            <img className={styles.deco3} src={require("../public/user.png")} />

            <div className={styles.cardTitle}>
              <h3>TESTIMONIAL</h3>
              <p>
                I was amazed with the attention to detail and professionalism
                demonstrated in the construction of our concrete slab. The end
                result is a sturdy, perfectly level surface ¡thank you for an
                excellent job!
              </p>
            </div>
            <div className={styles.contStart}>
              <div style={{ marginTop: 10 }}>
                <img src={require("../public/estrella.png")} />
              </div>
              <div>
                <span>Charlotte</span>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <img
              className={styles.deco1}
              src={require("../public/comillas.png")}
            />
            <img
              className={styles.deco2}
              src={require("../public/comillas.png")}
            />
            <img className={styles.deco3} src={require("../public/user.png")} />

            <div className={styles.cardTitle}>
              <h3>TESTIMONIAL</h3>
              <p>
                The treated wood deck you built in our backyard has completely
                transformed our outdoor space. We now have a perfect place to
                relax and enjoy the outdoors ¡thank you for making our dream
                come true!
              </p>
            </div>
            <div className={styles.contStart}>
              <div style={{ marginTop: 10 }}>
                <img src={require("../public/estrella.png")} />
              </div>
              <div>
                <span>James</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.contCard}>
          <div className={styles.card}>
            <img
              className={styles.deco1}
              src={require("../public/comillas.png")}
            />
            <img
              className={styles.deco2}
              src={require("../public/comillas.png")}
            />
            <img className={styles.deco3} src={require("../public/user.png")} />

            <div className={styles.cardTitle}>
              <h3>TESTIMONIAL</h3>
              <p>
                The gazebo they built in our backyard has become the focal point
                of our family gatherings. Its elegant design and attention to
                detail left us speechless. ¡We would definitely recommend your
                construction services!
              </p>
            </div>
            <div className={styles.contStart}>
              <div style={{ marginTop: 10 }}>
                <img src={require("../public/estrella.png")} />
              </div>
              <div>
                <span>Theodore</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer setActiveForm={()=>{setActiveForm(true)}} />
    </>
  );
};

export default Testimonials;
