import { useState } from "react";
import styles from "../styles/woodMaterial.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";
const WoodMaterials = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);
  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <div className={styles.contInfo}>
          <div className={styles.conTitle}>
            <h1>WOOD</h1>
          </div>
          <div className={styles.contMaterials}>
            <div>
              <img src={require("../public/wood1.png")} />
              <span>Wood Fence Pickets</span>
            </div>
            <div>
              <img src={require("../public/wood2.png")} />
              <span>Rails/ Baseboard/ Capboard</span>
            </div>
            <div>
              <img src={require("../public/wood3.png")} />
              <span>Boards/Lumber/ Timber</span>
            </div>
            <div>
              <img src={require("../public/wood4Material.png")} />
              <span>Decking/Lattice/ Siding</span>
            </div>
          </div>
        </div>
        <div className={styles.conImage}>
          <img
            className={styles.image}
            src={require("../public/woodRecurse.png")}
          />
          <div></div>
        </div>
      </div>
      <div className={styles.containerReverse}>
        <div className={styles.conImage}>
          <img
            className={styles.image}
            src={require("../public/woodRecurse2.png")}
          />
          <div></div>
        </div>
        <div className={styles.contInfo}>
          <div className={styles.contElements}>
            <div>
              <div className={styles.contProducs}>
                <img src={require("../public/woodRecurse3.png")} />
                <span>Decorative Wood Fence Accents</span>
              </div>
              <div className={styles.contProducs}>
                <img src={require("../public/woodRecurse4.png")} />
                <span>Metal Posts For Wood Fence</span>
              </div>
            </div>
            <div className={styles.contOneProduct}>
              <div className={styles.contProducs}>
                <img src={require("../public/woodRecurse5.png")} />
                <span>Wood Sealer & Stain</span>
              </div>
            </div>
          </div>
          <div className={styles.contBton}>
            <button onClick={()=>{setActiveForm(true)}}>CONTACT US</button>
          </div>
          <div className={styles.contactUs}>
            <p>CALL US NOW 972 803 2896 FOR FREE ESTIMATE</p>
          </div>
        </div>
      </div>
      <Footer setActiveForm={()=>{setActiveForm(true)}} />
    </>
  );
};

export default WoodMaterials;
