import {useState} from "react";
import styles from "../styles/hadrailReference.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const HandrailReference = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <div className={styles.contProducts}>
          <div>
            <div>
              <img src={require("../public/ironResurse5.png")} />
              <span>Iron Handrail - 36 Inch x 94 Inch - Yukon (3ft)</span>
            </div>
            <div>
              <img src={require("../public/ironResurse6.png")} />
              <span>
                Iron Handrail Commercial - 40 Inch x 94 Inch - Yukon (3ft -4 in)
              </span>
            </div>
          </div>
          <div>
            <div>
              <img src={require("../public/ironResurse7.png")} />
              <span>Iron Handrail Rake - 36 Inch x 94 Inch - Yukon (3ft)</span>
            </div>
          </div>
          <div className={styles.contBotton}>
            <button onClick={()=>{setActiveForm(true)}} >CONTACT US </button>
          </div>
        </div>
        <div className={styles.contImage}>
          <img src={require("../public/woodRecurse6.png")} />
          <div></div>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </>
  );
};

export default HandrailReference;
