import React from 'react'
import styles from '../styles/alert.module.css'

const Alert = ({type,text,closeModal,alingItems = 'justify'}) => {
  return (
    <div  className={styles.contAlert}>
        <div className={styles.alert}>
            <div className={styles.contIcons}>
                {
                    type == 'err' ? 
                    <svg width="59" height="59" divBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="29.5" cy="29.5" r="29.5" fill="#FF7C74"/>
                        <circle cx="29.5" cy="29.5" r="24.5" fill="white"/>
                        <path d="M16.5 16L30.3468 30.0442L44 16L42 14L30 26L18.5 14L16.5 16Z" fill="#FF7C74"/>
                        <path d="M46 18L44 16L30.3468 30.0442L44 44L45.6936 42.0885L33.6936 30.0885L46 18Z" fill="#FF7C74"/>
                        <path d="M26.5 30L14 42L16 44L30.3468 30.0442L16.5 16L14.5 18L26.5 30Z" fill="#FF7C74"/>
                        <path d="M30 34L42 46L44 44L30.3468 30.0442L16 44L18 46L30 34Z" fill="#FF7C74"/>
                    </svg>
                    : type == 'succes'?
                    <svg width="59" height="59" divBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="29.5" cy="29.5" r="29.5" fill="#A0FF74"/>
                        <circle cx="29.5" cy="29.5" r="24.5" fill="white"/>
                        <path d="M17 32L25 37.5L42 15L46 18L26.5 44.5L14 36L17 32Z" fill="#A0FF74" stroke="#A0FF74"/>
                    </svg>
                    :
                    <svg width="89" height="89" divBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.3038 9.00001C41.6132 5.00001 47.3868 5 49.6962 9L77.842 57.75C80.1514 61.75 77.2646 66.75 72.6458 66.75H16.3542C11.7354 66.75 8.84862 61.75 11.158 57.75L39.3038 9.00001Z" fill="#FFA755"/>
                        <path d="M41.0359 17C42.5755 14.3333 46.4245 14.3333 47.9641 17L70.0477 55.25C71.5873 57.9167 69.6628 61.25 66.5836 61.25H22.4164C19.3372 61.25 17.4127 57.9167 18.9523 55.25L41.0359 17Z" fill="white"/>
                        <rect x="42" y="25" width="5" height="23" fill="#FFA755"/>
                        <ellipse cx="44.5" cy="55" rx="3.5" ry="4" fill="#FFA755"/>
                    </svg>
                }

            </div>
            <div>
                <p className={styles.title}>{type == 'err' ? '¡Error!': type == 'succes'? 'Excellent' : 'Alert'}</p>
            </div>
            <div>
                <p className={styles.text} style={{textAlign:alingItems}}>{text} </p>
            </div>
            <div className={styles.contBtn}>
                <button onClick={closeModal} className={styles.btn} >
                    <p className={styles.pBtn}>Ok</p>
                </button>
            </div>
        </div>
        
    </div>
  )
}

export default Alert