import { useState } from "react";
import Service from "../View/Service";
import Header from "./Header";
import styleService from "../styles/service.module.css";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const services = [
  {
    title: "Our Premium Construction Services",
    text: `At Javier's Fence, we pride ourselves in offering a wide range 
        of construction services designed to transform your ideas into 
        reality. Our experience and dedication allow us to deliver 
        exceptional results on every project, as well as residential and 
        commercial. Below are our key services:`,
    img: [require("../public/ourPremium1.jpeg")],
    buttonName: "",
    buttonActive: false,
    video:false
  },

  {
    title: "Wood and Metal Fences",
    text: `Add security and style to your property with our 
      expert wood and metal fence installations. 
      You can choose between the natural 
      beauty of wood or the durability of metal, 
      we offer both options to suit your aesthetic and 
      functional preferences. 
      It's ideal for giving security to your space.Our fences have 5 
      years warranty on installation`,
    img: [
      require("../public/service2.png"),
      require("../public/metalfense0.jpg"),
      require("../public/metalfense1.png"),
      require("../public/metalfense2.png"),
      require("../public/metalfense3.png"),
      require("../public/metalfense4.png"),
      require("../public/metalfense5.png"),
      require("../public/metalfense6.png"),
      require("../public/metalfense7.jpg"),
      require("../public/metalfense8.jpeg"),
    ],
    buttonName: "",
    buttonActive: false,
    video:false
  },
  {
    title: "Concrete, Stone and Brick Constructions",
    text: `Our construction experts work with precision to create 
      solid and visually stunning structures using hydraulic
      concrete from 3,000 to 5,000 PSI, stone and brick. 
      Whether it's a modern or classic design, our quality 
      materials guarantee long-lasting results. Learn more about
       the different options we have for stone projects.`,
    img: [
      require("../public/stone0.jpg"),
      require("../public/stone1.jpg"),
      require("../public/stone2.jpg"),
    ],
    buttonName: "",
    buttonActive: false,
    video:false
  },
  {
    title: "Residential and Commercial Painting",
    text: `Our construction experts work with precision to 
      create solid and visually stunning structures using hydraulic
      concrete from 3,000 to 5,000 PSI, stone and brick. Whether
       it's a modern or classic design, our quality materials guarantee l
      ong-lasting results. Learn more about the different options we 
      have for stone projects.`,
    img: [require("../public/service4.png")],
    buttonName: "",
    buttonActive: false,
    video:false
  },
  {
    title: "Retaining Walls in wood, Metal and Stone",
    text: `We offer retaining wall solutions that not only serve their purpose,
      but also add a touch of beauty and style to your environment. 
      From rustic wood walls to metal and stone structures, we are 
      prepared to create durable and attractive solutions.
      `,
    img: [
      require("../public/wood0.jpg"),
      require("../public/wood3.jpg"),
      require("../public/wood4.png"),
      require("../public/wood5.png"),
      require("../public/wood6.png"),
      require("../public/wood7.jpg"),
      require("../public/wood8.jpg"),
    ],
    buttonName: "",
    buttonActive: false,
    video:false
  },
  {
    title: "Pergola",
    text: `We design and elaborate from Pergolas with a rustic style to outdoor and modern covers, giving a different touch to your spaces. We have highly qualified professionals and very good quality materials.
    Our structures that not only are visually stunning, but also highly functional. We create comfortable and versatile spaces so you can enjoy your outdoor space to the fullest at any time of the year.
      `,
    img: [
      require("../public/cenador1.png"),
      require("../public/cenador2.png"),
      require("../public/cenador3.png"),
      require("../public/cenador4.png"),
      require("../public/cenador5.png"),
      require("../public/cenador6.png"),
      require("../public/cenador7.png"),
      require("../public/cenador8.jpg"),
      require("../public/cenador9.png"),
    ],
    buttonName: "",
    buttonActive: false,
    video:false
  },
  {
    title: "Custom Automatic Doors for Homes and Businesses",
    text: `We are proud to offer automatic door solutions that i
      mprove security, comfort and efficiency in residences and
      businesses, we also elaborate this type of doors in customized 
      models in order to provide protection to the property, you 
      will find with us: Professional installation, custom design, 
      maintenance and intelligent automation.
      `,
    img: [
      require("../public/custom1.MOV")
    ],
    buttonName: "",
    buttonActive: false,
    video:true
  },
];

const Services = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <div>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      {services.map((item, index) => (
        <Service
          key={index}
          title={item.title}
          text={item.text}
          img={item.img}
          index={index}
          buttonName={item.buttonName}
          buttonActive={item.buttonActive}
          video={item.video}
        />
      ))}
      <div className={styleService.footerServices}>
        <article>
          <p>
            We have several services for residential or commercial properties.
            Get ready to realize the project you want in your property!
          </p>
        </article>
        <div
          onClick={() => {
            setActiveForm(true);
          }}
        >
          <button>CONTACT US</button>
        </div>
      </div>
      <Footer setActiveForm={()=>{setActiveForm(true)}} />
    </div>
  );
};

export default Services;
