import {useState} from "react";
import styles from "../styles/pergola.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const Pergola = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);
  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <div className={styles.conImage}>
          <img src={require("../public/pergolaDeco1.png")} />
          <div></div>
        </div>
        <div className={styles.contPergola}>
          <div>
            <img src={require("../public/Recurso2.png")} />
          </div>
          <span>Pergola / Pergola Delux</span>
          <div className={styles.contBton}>
            <button onClick={()=>{setActiveForm(true)}}>CONTACT US </button>
          </div>
          <div>
            <p className={styles.conText}>
              CALL US NOW 972 803 2896 FOR FREE ESTIMATE
            </p>
          </div>
        </div>
      </div>
      <Footer setActiveForm={()=>{setActiveForm(true)}} />
    </>
  );
};

export default Pergola;
