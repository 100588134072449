import React, { useEffect, useState, useRef } from "react";
import styleService from "../styles/service.module.css";

const Service = ({ title, text, img, index,video}) => {
  const slideShow = useRef(null);

  useEffect(() => {
    let data 
    
    if(img.length > 1){
       data = setInterval(() => {
        siguiente();
      }, 3000);
  
    }
    
    return () => {
      clearInterval(data);
    };
  }, []);

  const siguiente = () => {
    if (slideShow.current.children.length > 0) {
      const firstElement = slideShow.current.children[0];

      slideShow.current.style.transition = `400ms ease-out all`;
      const tamañoSlide = slideShow.current.children[0].offsetWidth;

      slideShow.current.style.transform = `translateX(-${tamañoSlide}px)`;

      const transition = () => {
        slideShow.current.style.transition = "none";
        slideShow.current.style.transform = `translateX(0)`;

        slideShow.current.appendChild(firstElement);

        slideShow.current.removeEventListener("transitionend", transition);
      };

      slideShow.current.addEventListener("transitionend", transition);
    }
  };

  return (
    <div
      className={`${styleService.container} ${
        index % 2 == 0
          ? styleService.containerColor
          : styleService.containerColorIverted
      }`}
    >
      <div className={styleService.contText}>
        <div>
          <div>
            <h2 style={{ color: index % 2 == 0 ? "#0d0b35" : "#fff" }}>
              {title}
            </h2>
          </div>
          <div
            style={{ backgroundColor: index % 2 == 0 ? "#f4994e" : "#151220" }}
            className={styleService.decoTitle}
          ></div>
        </div>
        <article>
          <p style={{ color: index % 2 == 0 ? "#000" : "#fff" }}>{text}</p>
        </article>
       
      </div>
      <div className={styleService.contenedorPrincipal}>
        <div ref={slideShow} className={styleService.contenedorSlideShow}>
          {img.map((url, index) => (
            <div className={styleService.slide}>
              {
                video ?
                <video src={url} controls={true}></video>
                :<img src={url} />
              }
              
            </div>
          ))}
        </div>
        <div  className={styleService.decoImage}></div>
      </div>
    </div>
  );
};

/*

<div className={styleService.contImage}>
        <div className={styleService.containerImage}>
            <img src={picture} />
        </div>
        <div  className={styleService.decoImage}></div>
      </div>


*/

export default Service;
