import {useState} from "react";
import styles from "../styles/frequentQuestion.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const FrequentQuestions = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div>
        <div className={styles.contTitle}>
          <img src={require("../public/frequentRecurse.png")} />
          <div className={styles.decoTitle}></div>
          <div className={styles.title}>
            <h1>FREQUENTS QUESTIONS</h1>
            <span></span>
          </div>
        </div>
        <div className={styles.container}>
          <ol style={{ listStyleType: "none" }}>
            <li className={styles.list}>
              <h4>
                What are the benefits of building with exterior treated wood?{" "}
              </h4>
              <p>
                Building with exterior treated wood offers several benefits,
                such as increased weather resistance, durability, protection
                against insects and rot, and a natural aesthetic appearance.
              </p>
            </li>
            <li className={styles.list}>
              <h4>
                What is the durability of metal structures in construction?
              </h4>
              <p>
                Metal structures are known for their exceptional durability.
                Depending on the type of metal used, its proper maintenance and
                corrosion protection, metal structures can have a service life
                of several decades.
              </p>
            </li>
            <li className={styles.list}>
              <h4>
                What is the difference between a wooden fence and a metal fence?
              </h4>
              <p>
                The main difference between a wooden fence and a metal fence
                lies in the material used. Wood fences offer a warmer and more
                natural look, while metal fences are more durable, resistant and
                require less maintenance.
              </p>
            </li>
            <li className={styles.list}>
              <h4>
                What are the advantages of using retaining walls in terrace
                construction?
              </h4>
              <p>
                Retaining walls are beneficial in terrace construction because
                they provide structural stability, prevent soil erosion, allow
                for more uniform terrace levels, and create additional garden
                space on steep slopes.
              </p>
            </li>
            <li className={styles.list}>
              <h4>What types of roofs are ideal for rainy climates?</h4>
              <p>
                In rainy climates, waterproof roofs such as pitched roofs,
                polycarbonate roofs or sealant-treated wood decks are ideal to
                prevent water seepage and protect the area under the roof.
              </p>
            </li>
            <li className={styles.list}>
              <h4>
                What are the most common materials used in fence construction?{" "}
              </h4>
              <p>
                The most common materials used in fence construction are wood,
                metal (such as wrought iron or aluminum). Each material offers
                different characteristics in terms of appearance, durability and
                maintenance.
              </p>
            </li>
          </ol>
          <div className={styles.contBton}>
            <button onClick={()=>{setActiveForm(true)}} >ASK US</button>
          </div>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </>
  );
};

export default FrequentQuestions;
