import { useState } from "react";
import stylesPortfolio from "../styles/portfolio.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";
const Portfolio = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />

      <div className={stylesPortfolio.container}>
        <div className={stylesPortfolio.decorador1}>
          <img src={require("../public/deco2.png")} />
        </div>
        <div className={stylesPortfolio.decorador2}>
          <img src={require("../public/deco2.png")} />
        </div>
        <div className={stylesPortfolio.contTitle}>
          <div>
            <h2>PROJECT</h2>
          </div>
        </div>
        <div className={stylesPortfolio.contPortfolio}>
          <div className={stylesPortfolio.contPorfolioResidential}>
            <div
              className={`${stylesPortfolio.decoImage} ${stylesPortfolio.deco1}`}
            ></div>
            <img src={require("../public/service1.png")} />
            <div className={stylesPortfolio.contTextFolio}>
              <span>
                <a style={{color:'#fff'}} href="/residencialGalery">Residential</a>
              </span>
            </div>
            <div className={stylesPortfolio.decoText}></div>
          </div>
          <div className={stylesPortfolio.contPorfolioComercial}>
            <div
              className={`${stylesPortfolio.decoImage} ${stylesPortfolio.deco2}`}
            ></div>
            <img src={require("../public/service2.png")} />
            <div className={stylesPortfolio.contTextFolio}>
              <span>
                <a style={{color:'#fff'}} href="/ComercialGalery">Commercial</a>
              </span>
            </div>
            <div className={stylesPortfolio.decoText}></div>
          </div>
        </div>
      </div>
      <Footer setActiveForm={()=>{setActiveForm(true)}} />
    </>
  );
};

export default Portfolio;
