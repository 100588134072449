import { useState } from "react";
import styles from "../styles/stateGates.module.css";
import ironStyles from "../styles/IronMaterial.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const FensePanel = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <div className={ironStyles.conImage}>
          <img src={require("../public/woodRecurse6.png")} />
          <div
            style={{
              background: "rgb(255,155,73)",
              background:
                "linear-gradient(90deg, rgba(255,155,73,.4) 0%, rgba(255,114,43,.4) 100%)",
            }}
          ></div>
        </div>
        <div className={styles.contProducst}>
          <div>
            <div>
              <img src={require("../public/ironFense1.png")} />
              <span>Iron Fence Panel 48 Inch x 94 Inch - Yukon (4ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense2.png")} />
              <span>Iron Fence Panel 48 Inch x 94 Inch Fido (4ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense3.png")} />
              <span>Iron Fence Panel 36 Inch x 94 Inch Yukon (3ft)</span>
            </div>
          </div>
          <div>
            <div>
              <img src={require("../public/ironFense4.png")} />
              <span>Iron Fence Panel 48 Inch x 94 Inch - Metro (4ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense5.png")} />
              <span>Iron Fence Panel 60 Inch x 94 Inch - Yukon(5ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense6.png")} />
              <span>Iron Fence Panel 94 Inch x 94 Inch - Regal (4ft)</span>
            </div>
          </div>
          <div>
            <div>
              <img src={require("../public/ironFense7.png")} />
              <span>Iron Fence Panel 72 Inch x 94 Inch - Yukon (6ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense8.png")} />
              <span>Iron Fence Panel 60 Inch x 94 Inch - Regal (5ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense9.png")} />
              <span>Iron Fence Panel 48 Inch x 94 Inch - Summit (4ft)</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerReverse}>
        <div className={styles.contProducst}>
          <div>
            <div>
              <img src={require("../public/ironFense10.png")} />
              <span>Iron Fence Panel 60 Inch x 94 Inch - Denali (5ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense11.png")} />
              <span>Iron Fence Panel 72 Inch x 94 Inch - Regal (6ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense12.png")} />
              <span>Iron Fence Panel 48 Inch x 94 Inch - Denali (4ft)</span>
            </div>
          </div>
          <div>
            <div>
              <img src={require("../public/ironFense13.png")} />
              <span>Iron Fence Panel 72 Inch x 94 Inch - Summit (6ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense13.png")} />
              <span>Iron Fence Panel 60 Inch x 94 Inch - Summit (5ft)</span>
            </div>
            <div>
              <img src={require("../public/ironFense15.png")} />
              <span>
                Iron Fence Panel Commercial 96 Inch x 94 Inch - Summit (8ft)
              </span>
            </div>
          </div>
          <article className={styles.contBottonCenter}>
            <button
              onClick={() => {
                setActiveForm(true);
              }}
            >
              CONTACT US{" "}
            </button>
          </article>
        </div>
        <div className={ironStyles.conImage}>
          <img className={ironStyles.imageReverse} src={require("../public/woodRecurse6.png")} />
          <div
            style={{
              background: "rgb(255,155,73)",
              background:
                "linear-gradient(90deg, rgba(255,155,73,.4) 0%, rgba(255,114,43,.4) 100%)",
            }}
          ></div>
        </div>
      </div>
      <Footer
        setActiveForm={() => {
          setActiveForm(true);
        }}
      />
    </>
  );
};

export default FensePanel;
