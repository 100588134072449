import { useState, useEffect } from "react";
import styles from "../styles/residencialGalery.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const cantImages = 67;
const imagesPerLoad = 20;

const ComercialGalery = () => {
  const [images, setImages] = useState([]);
  const [imagesSelected, setImagesSelected] = useState([]);
  const [offset, setOffset] = useState(20);
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  useEffect(() => {
    const imagesCopied = [];

    for (let i = 1; i <= cantImages; i++) {
      try {
        const img = require(`../public/proyectosComerciales/comerciales-${i}.jpg`);
        imagesCopied.push({ id: i, source: img });
      } catch (error) {
        const img = require(`../public/proyectosComerciales/comerciales-${i}.PNG`);
        imagesCopied.push({ id: i, source: img });
      }
    }
    const imagesSelectedCopy = imagesCopied.slice(0, imagesPerLoad);
    setImages(imagesCopied);

    setImagesSelected([{ galery: [...imagesSelectedCopy] }]);
  }, []);

  useEffect(() => {
    const loadImages = () => {
      const start = offset;
      const end = start + imagesPerLoad;
      const newImages = images.slice(start, end);
      setImagesSelected([...imagesSelected, { galery: [...newImages] }]);
      setOffset(end);
    };

    const checkScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadImages();
      }
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [images, offset]);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled} />
      <div className={styles.container}>
        <h1>Commercial projects</h1>
        {imagesSelected.map((el, index) => (
          <div className={styles.galery} key={index}>
            {el.galery.map((item) => (
              <div className="pics" key={item.id}>
                <img src={item.source} style={{ width: "100%" }} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ComercialGalery;
