import {useState} from "react";
import stylesWhoAreWe from "../styles/WhoAreWe.module.css";
import Header from "./Header";
import ContactForm from "../View/ContactForm";
import Footer from "../View/Footer";

const WhoAreWe = () => {
  const [activeForm, setActiveForm] = useState(false);

  const isActiveForm = () => setActiveForm(!activeForm);
  const onDisabled = () => setActiveForm(false);

  return (
    <>
      <Header activeForm={isActiveForm} />
      <ContactForm isActive={activeForm} onDisabled={onDisabled}/>
      <div className={stylesWhoAreWe.container}>
        <div className={stylesWhoAreWe.contImage}>
          <img src={require("../public/WhoAreYouImage.png")} />
        </div>
        <div className={stylesWhoAreWe.contText}>
          <h2>Who Are You?</h2>
          <article>
            <p>
              Javier's Fence started in 2004 to build fences around the
              metroplex. Three things that distinguish us from others is that we
              never lie, we are always on time, and over all; we respect what is
              offered.{" "}
            </p>
            <p>
              Our goal is to keep our customers satisfied. All of our materials
              are selected to give you a better serivce.
            </p>
            <p>
              My dedication is to always be on my clients side and listen to
              their needs in terms of
            </p>
          </article>
        </div>
      </div>
      <Footer setActiveForm={()=>{setActiveForm(true)}} />
    </>
  );
};

export default WhoAreWe;
